import React from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  elements: {
    point:{
        radius: 0
    }
      },
  maintainAspectRatio: false,
  scales: {
    y: {
      display: false,
    },
    x:{
      display: false
    }
  },
  responsive: true,
  labels: {
    display: false
  },
  plugins: {
    legend: {
      display: false,
    },        
    title: {
      display: false,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'CPU',
      data: [40, 43, 42, 43, 43, 43, 45],
      borderColor: 'rgba(51,206,156,255)',
      backgroundColor: 'rgba(51,206,156,0.2)',
      borderWidth: 2,
    },
  ],
};

const ChartBirthsNums = () => {
  return (
    <div style={{width: "150px", height: "40px"}}>
        <Line options={options} data={data} />
    </div>
  )
}

export default ChartBirthsNums