import React from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  elements: {
    point:{
        radius: 0
    },
    line:{
      tension: 0.4
    }

      },
  
  scales: {
    y: {
      display: false,
    },
    x:{
      display: false
    }
  },
  responsive: true,
  labels: {
    display: false
  },
  plugins: {
    legend: {
      display: false,
    },        
    title: {
      display: false,
      text: 'System Usage',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'CPU',
      data: [100,90,95,88,90,92,102,87,90,88,88, 90,99,102],
      borderColor: 'rgba(51,206,156,255)',
      backgroundColor: 'rgba(51,206,156,0.1)',
      borderWidth: 2
    },
  ],
};

const LineChartBirths = () => {
  return (
    <div>
        <Line options={options} data={data} />
    </div>
  )
}

export default LineChartBirths