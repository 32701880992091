import {BIRTH_LIST_FAIL, BIRTH_LIST_REQUEST, BIRTH_LIST_SUCCESS} from '../constants/birthConstants'

export const birthReducer = (state = { births: []}, action) => {
    switch (action.type) {
        case BIRTH_LIST_REQUEST:
            return {loading : true, births: []}
            
        case BIRTH_LIST_SUCCESS:
            return {loading: false, births: action.payload
            }
        case BIRTH_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

