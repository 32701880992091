import React from 'react'
import { Container} from 'react-bootstrap'
import { Input, Col, Row, InputGroup } from 'rsuite';
import SmsNotifications from '../components/SmsNotifications';
import SearchIcon from '@rsuite/icons/Search';
import EmailNotifications from '../components/EmailNotifications';
import { IoSearchCircleOutline } from 'react-icons/io5';
import NotificationDetails from '../components/NotificationDetails';


const styles = {
  marginBottom: 10,
  borderRadius: 2
};
const CustomInput = ({ ...props }) => <Input {...props} style={styles} />;

const CustomInputGroup = ({ placeholder, ...props }) => (
  <InputGroup {...props} style={styles}>
    <Input placeholder={placeholder} />
    <InputGroup.Addon>
      <IoSearchCircleOutline />
    </InputGroup.Addon>
  </InputGroup>
);
const CustomInputGroupWidthButton = ({ placeholder, ...props }) => (
  <InputGroup {...props} inside style={styles}>
    <InputGroup.Button>
      <SearchIcon />
    </InputGroup.Button>
    <Input placeholder={placeholder} />
  </InputGroup>
);

const GeneralNotificationsScreen = ({sms, email}) => {
  return (
     <Container className=" c_wrapper">
        
          <div className="c_wrapper_header">
            <h2>General Notifications</h2>
          </div>
          <div className="c_wrapper_content">
            <Row className="c_wrapper_content_header flex flex-f">
              <Col className='flex flex-c'>
                <h5>General SMS</h5>
              </Col>
               <Col  className='flex flex-c' xs={24} sm={12} md={6}>
                <CustomInputGroupWidthButton size="sm" placeholder="Search..." />
              </Col>
            </Row>
            <SmsNotifications></SmsNotifications>
            
           
            <div className="c_wrapper_content_footer">
               
            </div>
          </div>
       
      </Container>
  )
}

export default GeneralNotificationsScreen
