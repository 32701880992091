import { SELECT_ADOPTIONS, SELECT_AUDITS_ADOPTIONS, SELECT_AUDITS_BIRTHS, SELECT_AUDITS_DEATHS, SELECT_BIRTHS, SELECT_DEATHS } from "../constants/selectionConstants"

export const listNotificationSelectionB = () => (dispatch) => {
        dispatch({type: SELECT_BIRTHS})
}

export const listNotificationSelectionD = () => (dispatch) => {
        dispatch({type: SELECT_DEATHS})
}
export const listNotificationSelectionA = () => (dispatch) => {
        dispatch({type: SELECT_ADOPTIONS})
}


// Audits
export const listAuditSelectionB = () => (dispatch) => {
        dispatch({type: SELECT_AUDITS_BIRTHS})
}

export const listAuditSelectionD = () => (dispatch) => {
        dispatch({type: SELECT_AUDITS_DEATHS})
}
export const listAuditSelectionA = () => (dispatch) => {
        dispatch({type: SELECT_AUDITS_ADOPTIONS})
}




