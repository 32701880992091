import{ METRICS_LIST_REQUEST, METRICS_LIST_SUCCESS, METRICS_LIST_FAIL} from '../constants/metricsConstants'
import axios from 'axios'

export const listMetrics = () => async (dispatch) => {
    try {
        dispatch({type: METRICS_LIST_REQUEST})

        const {data} = await axios.get('https://bdar.cml.ug/api/v1/notifications/stats')

        dispatch({type: METRICS_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: METRICS_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}


