import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listAudits } from '../actions/auditActions';
import AuditData from '../components/AuditData';
import AuditTableBirths from '../components/AuditTableBirths';
import { ErrorBoundary } from '../Utils/ErrorBoundary';
import { Col, Container, Input, InputGroup, Loader, Row } from 'rsuite';
import { IoSearchCircleOutline } from 'react-icons/io5';
import SearchIcon from '@rsuite/icons/Search';
import AuditTableAdoptions from '../components/AuditTableAdoptions';
import AuditTableDeaths from '../components/AuditTableBirthsDeaths';




const Audit = () => {
  const styles = {
  marginBottom: 10,
  borderRadius: 2
};

const CustomInput = ({ ...props }) => <Input {...props} style={styles} />;

const CustomInputGroup = ({ placeholder, ...props }) => (
  <InputGroup {...props} style={styles}>
    <Input placeholder={placeholder} />
    <InputGroup.Addon>
      <IoSearchCircleOutline />
    </InputGroup.Addon>
  </InputGroup>
);

const CustomInputGroupWidthButton = ({ placeholder, ...props }) => (
  <InputGroup {...props} inside style={styles}>
    <InputGroup.Button>
      <SearchIcon />
    </InputGroup.Button>
    <Input placeholder={placeholder} />
  </InputGroup>
);


  const dispatch = useDispatch()

  useEffect(() => {
  dispatch(listAudits())

}, [dispatch])
const auditList = useSelector((state) => state.auditList)
const {loading, error, audits} = auditList
const auditSelection = useSelector((state)=> state.auditSelection)
const selected = auditSelection.auditSelection
let births = selected === 'births'
let deaths = selected === 'deaths'
let adoptions = selected === 'adoptions'

console.log(selected)

  return (
    <ErrorBoundary>
      <Container className=" c_wrapper">
        
          <div className="c_wrapper_header">
            <h2>Audits</h2>
          </div>
          <div className="c_wrapper_content">
            <Row className="c_wrapper_content_header flex flex-f">
              <Col className='flex flex-c'>
                <h5> {births ? 'Birth' : deaths ? 'Death' : adoptions ? 'Adoption' : <span></span>} Audits</h5>
              </Col>
              <Col  className='flex flex-c' xs={24} sm={12} md={6}>
                <CustomInputGroupWidthButton size="sm" placeholder="Search..." />
              </Col>
            </Row>
            { selected === 'births' ? (<AuditTableBirths></AuditTableBirths>): selected === 'deaths' ? (<AuditTableDeaths></AuditTableDeaths>): selected === 'adoptions' ? (<AuditTableAdoptions></AuditTableAdoptions>) : (<div></div>)}
            
      
            <div className="c_wrapper_content_footer">
               
            </div>
          </div>
       
      </Container>
    </ErrorBoundary>
  )
}

export default Audit