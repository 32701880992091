export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST'
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS'
export const NOTIFICATION_LIST_FAIL = 'NOTIFICATION_LIST_FAIL'

export const SMS_NOTIFICATION_LIST_REQUEST = 'SMS_NOTIFICATION_LIST_REQUEST'
export const SMS_NOTIFICATION_LIST_SUCCESS = 'SMS_NOTIFICATION_LIST_SUCCESS'
export const SMS_NOTIFICATION_LIST_FAIL = 'SMS_NOTIFICATION_LIST_FAIL'

export const SMS_NOTIFICATION_DETAILS_REQUEST = 'SMS_NOTIFICATION_DETAILS_REQUEST'
export const SMS_NOTIFICATION_DETAILS_SUCCESS = 'SMS_NOTIFICATION_DETAILS_SUCCESS'
export const SMS_NOTIFICATION_DETAILS_FAIL = 'SMS_NOTIFICATION_DETAILS_FAIL'

export const EMAIL_NOTIFICATION_LIST_REQUEST = 'EMAIL_NOTIFICATION_LIST_REQUEST'
export const EMAIL_NOTIFICATION_LIST_SUCCESS = 'EMAIL_NOTIFICATION_LIST_SUCCESS'
export const EMAIL_NOTIFICATION_LIST_FAIL = 'EMAIL_NOTIFICATION_LIST_FAIL'

export const EMAIL_NOTIFICATION_DETAILS_REQUEST = 'EMAIL_NOTIFICATION_DETAILS_REQUEST'
export const EMAIL_NOTIFICATION_DETAILS_SUCCESS = 'EMAIL_NOTIFICATION_DETAILS_SUCCESS'
export const EMAIL_NOTIFICATION_DETAILS_FAIL = 'EMAIL_NOTIFICATION_DETAILS_FAIL'






