import React, { useEffect, useState } from 'react';
import { Container, IconButton, Table, Tag, Loader, Pagination } from 'rsuite';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useDispatch, useSelector } from 'react-redux';
import { listNotifications } from '../actions/notificationActions ';

const adoptions = [
  {id: 1,
   application_number: 'AD0280',
   name: "Aaron Omach",
   application_type: "Adoption",
   createdAt: '28.12.1023',
   sms_status: 'sent',
   email_status: 'sent',
   email: 'aaron.omach@cml.ug',
   primary_phone_number: '0782531813',
   sms_time: '2022-11-09 08:47:06',
   email_time: '2022-11-09 08:48:06'
  },
   {id: 2,
   application_number: 'AD0281',
   name: "Beatrice Kakungulu",
   application_type: "Adoption",
   createdAt: '28.12.1023',
   sms_status: 'sent',
   email_status: 'sent',
   email: 'beatricek@gmail.com',
   primary_phone_number: '0782531813',
   sms_time: '2022-11-09 08:47:06',
   email_time: '2022-11-09 08:48:06'
  },
  {id: 3,
   application_number: 'AD0282',
   name: "Cherukut Gilbert",
   application_type: "Adoption",
   createdAt: '28.12.1023',
   sms_status: 'sent',
   email_status: 'failed',
   email: 'gilbert.cherukut@gmail.com',
   primary_phone_number: '0782531813',
   sms_time: '2022-11-09 08:47:06',
   email_time: '2022-11-09 08:48:06'
  }

]

const { Column, HeaderCell, Cell } = Table;

const rowKey = 'id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some(key => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

const renderRowExpanded = rowData => {
  return (
    <div style={{paddingLeft: '50px'}} className="sub-not-container">
      <div className='sub-not-headers grid grid-4'>
        <p>Notification Type</p>
        <p>Contact</p>
        <p>Status</p>
        <p>Time</p>
      </div>
      <div className="sub-not grid grid-4">
        <p>SMS</p>
        <p>{rowData.primary_phone_number}</p>
        <p>{rowData.sms_status}</p>
        <p>{rowData.createdAt}</p>


      </div>
      <div className="sub-not grid grid-4">
        <p>Email</p>
        <p>{rowData.email}</p>
        <p>{rowData.email_status}</p>
        <p>{rowData.createdAt}</p>




      
      </div>
      
      
    </div>
  );
};

const NotificationTableAdoptions = () => {
const [sortColumn, setSortColumn] = React.useState();
const [sortType, setSortType] = React.useState();
const [loadingTable, setLoadingTable] = React.useState(false);

const dispatch = useDispatch()
const notificationList = useSelector((state) => state.notificationList)
const {loading, error, notifications} = notificationList
const userLogin = useSelector((state) => state.userLogin);
const { userInfo } = userLogin;

useEffect(() => {

    dispatch(listNotifications())

  }, [dispatch])

const getData = () => {
    if (sortColumn && sortType) {
      return adoptions.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return adoptions;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoadingTable(true);
    setTimeout(() => {
      setLoadingTable(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 300);
  };

  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach(key => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  // Pagination
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };


  return (
    <Container>
        {notifications.success ? ( <Table
      shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
      data={getData()}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      renderRowExpanded={renderRowExpanded}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loadingTable}
      autoHeight={true}
      cellBordered={false}
      virtualized={false}
      bordered
    >
      <Column width={70} align="center">
        <HeaderCell>...</HeaderCell>
        <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
      </Column>

      <Column width={250}>
        <HeaderCell>Document Id</HeaderCell>
        <Cell dataKey="application_number" />
      </Column>
       <Column width={250} sortable>
        <HeaderCell>Name</HeaderCell>
        <Cell dataKey="name"/>
        
      </Column>

      <Column width={300}>
        <HeaderCell>Document Type</HeaderCell>
        <Cell dataKey="application_type" />
      </Column>
       <Column width={300}>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="createdAt" />
      </Column>
    </Table>): (<Loader/>)}
          {adoptions ? (<div className='table_pag' style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={getData().length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>): (<Loader size="md" backdrop content="loading notifications..." vertical ></Loader>)}
        
    </Container>
    
  );
};

export default NotificationTableAdoptions