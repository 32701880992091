import {METRICS_LIST_FAIL, METRICS_LIST_REQUEST, METRICS_LIST_SUCCESS} from '../constants/metricsConstants'

export const metricsReducer = (state = { metrics: []}, action) => {
    switch (action.type) {
        case METRICS_LIST_REQUEST:
            return {loading : true, metrics: []}
            
        case METRICS_LIST_SUCCESS:
            return {loading: false, metrics: action.payload
            }
        case METRICS_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

