import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listEmailNotifications, listSmsNotifications } from '../actions/notificationActions '
import { Table, Loader, Container, Button } from 'rsuite';
import NotificationDetailsEmail from './NotificationDetailsEmail';

const { Column, HeaderCell, Cell } = Table;

const EmailNotifications = () => {
  const dispatch = useDispatch()
  const emailNotificationList = useSelector((state) => state.emailNotificationList)
  const {loading, error, emailNotifications} = emailNotificationList
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const token = userInfo.token
  const [showDetailsTable, setShowDetailsTable] = useState(false)
   const [showModel, setShowModel] = useState(false)
  const [selectedNot, setSelectedNot] = useState('')
  const modelFunc = (id) => {
    if(!showModel){
      setShowModel(true)}
      setSelectedNot(id.id)
    }
  const openTable = () => setShowDetailsTable(!showDetailsTable)
  console.log(showDetailsTable)
useEffect(() => {
  dispatch(listEmailNotifications(token))
 
  }, [dispatch])

  return (
    <Container>
      {emailNotifications.success ? (<Table virtualized height={400} data={emailNotifications.notifications}>
      <Column width={70} align="center" fixed>
        <HeaderCell>D/N</HeaderCell>
        <Cell dataKey="id" />
      </Column>

      <Column width={230}>
        <HeaderCell>Email address</HeaderCell>
        <Cell dataKey="email" />
      </Column>
       <Column width={100}>
        <HeaderCell>Send Time</HeaderCell>
        <Cell dataKey="createdAt" />
      </Column>
      <Column width={200}>
        <HeaderCell>Subject</HeaderCell>
        <Cell dataKey="subject" />
      </Column>

      <Column width={400}>
        <HeaderCell>Body</HeaderCell>
        <Cell dataKey="body" />
      </Column>
      <Column width={70} fixed='right'>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>
      
      <Column width={80} fixed="right">
        <HeaderCell>...</HeaderCell>

        <Cell style={{ padding: '6px' }}>
           {rowData => (
            <Button appearance="link" onClick={()=>{ openTable(); modelFunc({id: rowData.id})}}>
              Details
            </Button>
          )}
        </Cell>
      </Column>
    </Table>) : (<Loader/>)}
    {showDetailsTable ? (<NotificationDetailsEmail type="email" not_id={selectedNot} show={showModel}></NotificationDetailsEmail>): (<div></div>)}

      
    </Container>
  )
}

export default EmailNotifications

