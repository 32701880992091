import { SELECT_ADOPTIONS, SELECT_AUDITS_ADOPTIONS, SELECT_AUDITS_BIRTHS, SELECT_AUDITS_DEATHS, SELECT_BIRTHS, SELECT_DEATHS } from "../constants/selectionConstants"

export const notificationSelections = (state = { notificationSelection: 'births'}, action) => {
    switch (action.type) {
        case SELECT_BIRTHS:
            return {loading : false, notificationSelection: 'births'}
            
        case SELECT_DEATHS:
            return {loading: false, notificationSelection: 'deaths'
            }
        case SELECT_ADOPTIONS:
            return {loading: false, notificationSelection: 'adoptions'
            }
        default:
            return state
    }

}

export const auditSelections = (state = { auditSelection: 'births'}, action) => {
    switch (action.type) {
        case SELECT_AUDITS_BIRTHS:
            return {loading : false, auditSelection: 'births'}
            
        case SELECT_AUDITS_DEATHS:
            return {loading: false, auditSelection: 'deaths'
            }
        case SELECT_AUDITS_ADOPTIONS:
            return {loading: false, auditSelection: 'adoptions'
            }
        default:
            return state
    }

}