import{ BIRTH_LIST_REQUEST, BIRTH_LIST_SUCCESS, BIRTH_LIST_FAIL} from '../constants/birthConstants'
import axios from 'axios'

export const listBirths = (agent) => async (dispatch) => {
    try {
        dispatch({type: BIRTH_LIST_REQUEST})

        const {data} = await axios.post('https://bdar.cml.ug/api/v1/notifications/notifications', { status: 'all', type: 'all', agent: agent, start: "", end: ""})

        dispatch({type: BIRTH_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: BIRTH_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}


