import{ AUDIT_LIST_REQUEST, AUDIT_LIST_SUCCESS, AUDIT_LIST_FAIL} from '../constants/auditConstants'
import axios from 'axios'

export const listAudits = () => async (dispatch) => {
    try {
        dispatch({type: AUDIT_LIST_REQUEST})

        const {data} = await axios.post('https://bdar.cml.ug/api/v1/notifications/audit-trails', { action: "all", outcome: "all", agent: "all", start: "", end: ""})

        dispatch({type: AUDIT_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: AUDIT_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}


