import React from 'react'

function MetricsNums({count, name}) {
  return (
    <div className='metrics_nums_comp'>
      <div className="countNum" >{count}</div>
      <div className="countName" style={{fontSize: "10px", color: '#cacaca'}}>{name}</div>
    </div>
  )
}

export default MetricsNums
