import React, { useEffect } from 'react'
import { Modal, ButtonToolbar, Button, Loader, Placeholder } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux'
import { emailNotificationDetails, smsNotificationDetails } from '../actions/notificationActions '

function NotificationDetailsEmail({type, not_id,show}) {
  const [open, setOpen] = React.useState(show);
  const [rows, setRows] = React.useState(0);
  const handleClose = () => setOpen(false);

  const handleEntered = () => {
    setTimeout(() => setRows(10), 500);
  };

  const dispatch = useDispatch()
  const emailNotification = useSelector((state) => state.emailNotification)


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const {loading, emailDetails} = emailNotification
  const token = userInfo.token
  useEffect(() => {
  dispatch(emailNotificationDetails(token, not_id))
 
  }, [dispatch])


  return (
    <>
      <ButtonToolbar>
      </ButtonToolbar>

      <Modal
        open={open}
        onClose={handleClose}
        onEntered={handleEntered}
        onExited={() => {
          setRows(0);
        }}
        backdrop='static'
      >
        <Modal.Header>
          <Modal.Title style={{textTranform: 'uppercase'}}>{type} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(emailDetails.success && type === 'email' ) ? (<div>
              <div className='notField'>
                <h5>Receiver </h5> <p>{emailDetails.email.email}</p>
              </div>
              <div className='notField'>
                <h5>Subject </h5> <p>{emailDetails.email.subject}</p>
              </div>
              <div className='notField'>
                <h5>Body</h5> <p>{emailDetails.email.body}</p>
              </div>
              <div className='notField'>
                <h5>Status</h5> <p className={(emailDetails.email.status === 'sent') ? 'notSent' : 'notFailed'}> <span>{emailDetails.email.status}</span> </p>
              </div>
              {emailDetails.email.status === 'failed' ? (<div className='notField'>
                <h5>Reason</h5> <p>{emailDetails.email.reason}</p>
              </div>): (<></>)}
              <div className='notField'>
                <h5>Time</h5> <p>{emailDetails.email.createdAt}</p>
              </div>
          </div>) :(
            <div style={{ textAlign: 'center' }}>
              <Loader size="md" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NotificationDetailsEmail
