import "bootstrap"
import './App.css';

import React, {useState, useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import HomeScreen from './screens/HomeScreen';
import Metrics from "./screens/Metrics";
import Audit from "./screens/Audit";
import SideNav from "./components/SideNav";
import LoginPage from "./screens/LoginPage";
import TestScreen from "./screens/TestScreen";
import { useSelector } from "react-redux";
import ProtectedRoute from "./Utils/ProtectedRoute";
import UsersScreen from "./screens/UsersScreen";
import GeneralNotificationsScreen from "./screens/GeneralNotificationsScreen";
import CustomSidenav from "./components/CustomSidenav";
import GeneralNotificationsScreenEmail from "./screens/GeneralNotificationsScreenEmail";



function App() {
  const [userAvailable, setUserAvailable] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;


  return (
    <BrowserRouter>
    {(userInfo) ? (<CustomSidenav >
      <Routes>
        <Route path="/metrics" element={<Metrics/>}></Route>
        <Route path="/notifications/events" element={<HomeScreen/>}></Route>
        <Route path="/notifications/general/sms" element={<GeneralNotificationsScreen/>}></Route>
        <Route path="/notifications/general/email" element={<GeneralNotificationsScreenEmail/>}></Route>


        <Route path="/audits" element={<Audit/>}></Route>
        <Route path="/configuration" element={<TestScreen/>}></Route>
        <Route path="/users" element={<UsersScreen/>}></Route>
        <Route path="/" element={<LoginPage/>}></Route>

      </Routes>
    </CustomSidenav>):
    
    (
      <Routes>
        <Route path="/" element={<LoginPage/>}></Route>
      </Routes>
    )}
    
    </BrowserRouter>
  );
}

export default App;
