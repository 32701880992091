import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listSmsNotifications } from '../actions/notificationActions '
import { Table, Loader, Container, Button } from 'rsuite';
import { useState } from 'react';
import NotificationDetails from './NotificationDetails';


const { Column, HeaderCell, Cell } = Table;
const data = []

const SmsNotifications = () => {
  const dispatch = useDispatch()
  const smsNotificationList = useSelector((state) => state.smsNotificationList)
  const {loading, error, smsNotifications} = smsNotificationList
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const token = userInfo.token
  const [showDetailsTable, setShowDetailsTable] = useState(false)
  const [showModel, setShowModel] = useState(false)
  const [selectedNot, setSelectedNot] = useState('')
  const modelFunc = (id) => {
    if(!showModel){
      setShowModel(true)}
      setSelectedNot(id.id)
    }
  const openTable = () => setShowDetailsTable(!showDetailsTable)
  console.log(showDetailsTable)


useEffect(() => {
  dispatch(listSmsNotifications(token))
 
  }, [dispatch])

  return (
    <Container>
      {smsNotifications.success ? (<Table virtualized height={400} data={smsNotifications.notifications}>
      <Column width={70} align="center" fixed>
        <HeaderCell>D/N</HeaderCell>
        <Cell dataKey="id" />
      </Column>

      <Column width={200}>
        <HeaderCell>Contact</HeaderCell>
        <Cell dataKey="contact" />
      </Column>
       <Column width={250}>
        <HeaderCell>Send Time</HeaderCell>
        <Cell dataKey="createdAt" />
      </Column>

      <Column width={450}>
        <HeaderCell>Body</HeaderCell>
        <Cell dataKey="body" />
      </Column>
      <Column width={70} fixed='right'>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>
      <Column width={80} fixed="right">
        <HeaderCell>...</HeaderCell>

        <Cell style={{ padding: '6px' }}>
          {rowData => (
            <Button appearance="link" onClick={()=>{ openTable(); modelFunc({id: rowData.id})}}>
              Details
            </Button>
          )}
        </Cell>
      </Column>
    </Table>) : (<Loader/>)}
    {showDetailsTable ? (<NotificationDetails type="SMS" not_id={selectedNot} show={showModel}></NotificationDetails>): (<div></div>)}

        
      
    </Container>
  )
}

export default SmsNotifications

