import {AUDIT_LIST_FAIL, AUDIT_LIST_REQUEST, AUDIT_LIST_SUCCESS} from '../constants/auditConstants'

export const auditReducer = (state = { audits: []}, action) => {
    switch (action.type) {
        case AUDIT_LIST_REQUEST:
            return {loading : true, audits: []}
            
        case AUDIT_LIST_SUCCESS:
            return {loading: false, audits: action.payload
            }
        case AUDIT_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

