import axios from 'axios'

import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Loader, Message, Modal, Placeholder, Table, useToaster } from 'rsuite'
import { Form, Button } from 'react-bootstrap'
import { listUsers } from '../actions/userActions'
import {HiUserAdd} from 'react-icons/hi'
import SelectPicker from 'rsuite/SelectPicker';
import InputPicker from 'rsuite/InputPicker'
import { ErrorBoundary } from '../Utils/ErrorBoundary'
import {register} from '../actions/userActions'


const { Column, HeaderCell, Cell } = Table;


const styles = {
  radioGroupLabel: {
    padding: '8px 12px',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
};
const selectData = ['admin', 'netset', 'user'].map(item => ({
  label: item,
  value: item
}));


const UsersScreen = () => {
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [password, setPassword] = useState('');
const [passwordConfirm, setPasswordConfirm] = useState('');
const [role, setRole] = useState('');


const [sortColumn, setSortColumn] = React.useState();
const [sortType, setSortType] = React.useState();
const [loadingTable, setLoadingTable] = React.useState(false);

const dispatch = useDispatch()
const userList = useSelector((state) => state.userList)
const { loading, error, users } = userList
const userLogin = useSelector((state) => state.userLogin)
const { userInfo } = userLogin
useEffect(() => {
      dispatch(listUsers())
  }, [dispatch])

const [open, setOpen] = React.useState(false);
const [backdrop, setBackdrop] = React.useState('static');
const [pendingRegister, setPendingRegister] = React.useState(false);

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

 //Toaster
  const toaster = useToaster();
 
  const showAlert = (type, message)=>{
    toaster.push(<Message showIcon type={type}>{message}</Message>, {duration: 1000, placement:'topCenter'})
  }
const handleSignup = async (e) => {
  e.preventDefault()
  setPendingRegister(true)
  const config = {
        headers: { Authorization: `Bearer ${userInfo.token}` }
  };
  const result = await axios.post('https://bdar.cml.ug/api/v1/auth/signup', {name, email, phone, password, passwordConfirm, role}, config)
  setPendingRegister(false)
  if(result.data.success){
    showAlert("success", result.data.message)
    
  }else if(!result.data.success){
    console.log(result)
     showAlert("error", result.data.message)

  }

}


const getData = () => {
    if (sortColumn && sortType) {
      return users.data.data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return users;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoadingTable(true);
    setTimeout(() => {
      setLoadingTable(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 300);
  };
  return (
   
    <Container className='c_wrapper'>
      <div className="c_wrapper_header flex flex-f">
        <h3>Manage Users</h3>
      </div>
      <div className="c_wrapper_content">

        <div className="c_wrapper_content_header flex flex-f">
        <div></div>
        <Button onClick={handleOpen} appearance='primary' color='blue' style={{borderRadius: '2px'}}><HiUserAdd/>Add New User</Button>

        </div>
            {users ? ( <Table
      height={300}
      data={getData()}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loadingTable}
      bordered
      autoHeight
      cellBordered
    >
      <Column width={300} align="left" sortable>
        <HeaderCell>Name</HeaderCell>
        <Cell dataKey="name" />
      </Column>

      <Column width={270}>
        <HeaderCell>Contact</HeaderCell>
        <Cell dataKey="phone" />
      </Column>

      <Column width={200} sortable>
        <HeaderCell>Role</HeaderCell>
        <Cell dataKey="role" />
      </Column>

      <Column width={400} sortable>
        <HeaderCell>Email</HeaderCell>
        <Cell dataKey="email" />
      </Column>
    </Table>) : loading ? (<Loader size="md" backdrop content="loading users..." vertical ></Loader>) : (<Message showIcon type='error' >Error: {error}</Message>)}
      </div>
      

    <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Create new user</Modal.Title>
        </Modal.Header>
       
           <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="userName">
                <Form.Label>Names</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </Form.Group>
               <Form.Group className="mb-3" controlId="userPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="Enter phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="userPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={(e)=> setPassword(e.target.value)} value={password} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="userPasswordConfirm">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control type="password" placeholder="Confirm password" onChange={(e)=> setPasswordConfirm(e.target.value)} value={passwordConfirm} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label >Select Role</Form.Label>
                <Form.Select id="disabledSelect" value={role} onChange={(e)=> setRole(e.target.value)}>
                  <option>--Select Role--</option>
                  <option>admin</option>
                  <option>user</option>
                  <option>netset</option>
                </Form.Select>
              </Form.Group>
              {pendingRegister ? 
              (<>
              
              <Button disabled variant="primary" type="submit" onClick={handleSignup}>
                <Loader size="xs" content="" /> Adding user...
              </Button></> ) : (<Button variant="primary" type="submit" onClick={handleSignup}>
                Add User
              </Button>)}
              
            </Form>
           </Modal.Body>
        
      </Modal>
    </Container>
    
  )
}

export default UsersScreen
