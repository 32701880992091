import React from 'react'
import SearchIcon from '@rsuite/icons/Search';
import { Button, Col, Form, Input } from 'rsuite'
import axios from 'axios';
import { useState } from 'react';

const SearchBox = () => {
    // const [doc, setDoc] = useState("")
    const [doc, setDoc] = useState("");

    const searchItem = async()=>{
        console.log(doc)
        const result = await axios.get(`http://localhost:2020/birth-records/${doc}`)
        console.log(result)
        return result
    }
  return (
    <Col className='searchContainer'>
    <Form className= "flex searchForm" onSubmit={searchItem}>
        <Form.Control placeholder='Search...' value={doc} onChange={(e) => setDoc(e.target.value)} ></Form.Control>
        <Button> <SearchIcon /></Button>
    </Form>
    </Col>
  )
}

export default SearchBox