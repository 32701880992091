import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { login } from "../actions/userActions";
import axios from 'axios';

import { Message, useToaster, Loader, ButtonToolbar } from 'rsuite';



const LoginPage = ({location}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const [resetEmail, setResetEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showTokenPage, setShowTokenPage] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(true);
  const [showResetPage, setShowResetPage] = useState(false);
  const [pendingSendEmail, setPendingSendEmail] = useState(false);
  const [pendingResetPassword, setPendingResetPassword] = useState(false);

  const [pendingLogin, setPendingLogin] = useState(false);






  const [password, setPassword] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  useEffect(() => {
    if (userInfo) {
      navigate("/metrics");
    }
  }, [navigate, userInfo]);

 
  //Toaster
  const toaster = useToaster();
 
  const showAlert = (type, message)=>{
    toaster.push(<Message showIcon type={type}>{message}</Message>, {duration: 1000, placement:'topCenter'})
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(login(email, password));
    if(error){
      showAlert("error", error) 
    }
  };

  const handleSendRestEmail = async(e)=>{
    e.preventDefault()
    setPendingSendEmail(true)
    const result = await axios.post('https://bdar.cml.ug/api/v1/auth/users/forgotPassword', {email: resetEmail})
    setPendingSendEmail(false)
    if(result.data.success){
      showAlert("success", result.data.message)
      setShowResetPage(true)
      setShowTokenPage(false)
      setShowLoginPage(false)
    }else if(!result.data.success){
      showAlert("error", result.data.message)
    }
  }
   const handleRestPassword = async (e)=>{
    e.preventDefault()
    setPendingResetPassword(true)
    const result = await axios.post('https://bdar.cml.ug/api/v1/auth/users/resetPassword', {email: resetEmail, otp, password: newPassword, passwordConfirm: newPasswordConfirm})
    setPendingResetPassword(false)

    if(result.data.success){
      showAlert("success", result.data.message)
      setShowResetPage(false)
      setShowTokenPage(false)
      setShowLoginPage(true)
    }else if(!result.data.success){
      showAlert("error", result.data.message)
    }

    
  }

  
  return (
	<>
	  <div style={{width: '100%', backgroundColor: '#e88989'}}>
	  	<p style={{textAlign: 'center', fontWeight: 'bold', padding: '10px'}}>USPC BDAR Test Environment</p>
	  </div>
    <Container className='c_wrapper flex c-flex'>
      <Form  className='loginForm c-shadow-2'>
        <div className="formHeader">
          <div className="logo">
            <div className="logoImg flex c-flex">
              <img src="https://hrm.nira.go.ug/media/custom/images/nira-logo-login.png" alt="" style={{width: '50px'}}/>
              <h2 style={{color: '#cb191f', fontWeight: '600', fontSize:'3rem', marginLeft: '5px'}}>NIRA</h2>
            </div>
            <div className="logoDesc flex c-flex">
              <p style={{fontSize: '10px', marginTop: '5px'}}>Births, Deaths and Adoptions Registry</p>
            </div>
          </div>
        </div>
        {(showLoginPage) ? 
        (<><Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email}
            onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" value={password}
            onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>

        {pendingLogin ? (<ButtonToolbar><Button appearance="primary" disabled>
      Logging in...
    </Button ></ButtonToolbar> ) :  (<> <Button variant="primary" type="submit" style={{marginBotton: '15px'}} onClick={ (e) => handleLogin(e)}>
          Login
        </Button>
        <button className='passwordResetLink' onClick={()=> {
          setShowTokenPage(true)
          setShowResetPage(false)
          setShowLoginPage(false)}}> Forgot Password?</button>
         </>)}</>) : 
         (showTokenPage) ?
        (<><Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)} />
        </Form.Group>
        {pendingSendEmail ? (<Button disabled variant="primary" type="submit" style={{marginBotton: '15px'}}>
          Sending OTP...
        </Button>): !pendingSendEmail ? (<Button variant="primary" type="submit" style={{marginBotton: '15px'}} onClick={(e) => handleSendRestEmail(e)}>
          Send Reset OTP
        </Button>) : (<></>)}
        
        <button className='passwordResetLink' onClick={()=> {
          setShowTokenPage(false)
          setShowResetPage(false)
          setShowLoginPage(true)}} > Back to Login</button> </>): 
        (showResetPage) ? 
        (<>
        <Form.Group className="mb-2 mt-5" controlId="formBasicResetEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={resetEmail}
            onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicOtp">
          <Form.Label>OTP</Form.Label>
          <Form.Control type="text" placeholder="OTP" value={otp}
            onChange={(e) => setOtp(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicNewPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" placeholder="New Password" value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicNewPasswordConfirm">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control type="password" placeholder="Password" value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" style={{marginBotton: '15px'}} onClick={(e) => handleRestPassword(e)}>
          Reset Password
        </Button>
        <button className='passwordResetLink' onClick={()=> {
          setShowTokenPage(false)
          setShowResetPage(false)
          setShowLoginPage(true)}} > Back to Login</button>
        </>): 
        (<></>)}
        
    
      </Form>
      
    </Container>
	  </>
  )
}

export default LoginPage
