import React, { useEffect, useState } from 'react';
import { Container, IconButton, Table, Tag, Loader, Pagination } from 'rsuite';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useDispatch, useSelector } from 'react-redux';
import { listBirths } from '../actions/birthActions';
import { listNotifications } from '../actions/notificationActions ';

const { Column, HeaderCell, Cell } = Table;

const rowKey = 'id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some(key => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

const renderRowExpanded = rowData => {
  return (
    <div style={{paddingLeft: '30px'}}>
      <p>SMS: {rowData.email}</p>
      <p>Email: {rowData.phone}</p>
    </div>
  );
};

const NotificationTableBirths = () => {
const [sortColumn, setSortColumn] = React.useState();
const [sortType, setSortType] = React.useState();
const [loadingTable, setLoadingTable] = React.useState(false);

const dispatch = useDispatch()
const birthList = useSelector((state) => state.birthList)
const {loading, error, births} = birthList
const userLogin = useSelector((state) => state.userLogin);
const { userInfo } = userLogin;

useEffect(() => {
  
    dispatch(listBirths(1))
    dispatch(listNotifications(userInfo.token))


  }, [dispatch])

const getData = () => {
    if (sortColumn && sortType) {
      return births.data.notifications.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return births.data.notifications;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoadingTable(true);
    setTimeout(() => {
      setLoadingTable(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 300);
  };

  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach(key => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  // Pagination
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };


  return (
    <Container>
        {births.success ? ( <Table
      shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
      data={getData()}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      renderRowExpanded={renderRowExpanded}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loadingTable}
      autoHeight={true}
      cellBordered={false}
      virtualized={false}
      bordered
    >
      <Column width={50} align="center">
        <HeaderCell>...</HeaderCell>
        <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
      </Column>

      <Column width={150}>
        <HeaderCell>Document Id</HeaderCell>
        <Cell dataKey="doc_ref" />
      </Column>
       <Column width={250}>
        <HeaderCell>Contact</HeaderCell>
        <Cell dataKey="contact" />
      </Column>

      <Column width={200} sortable>
        <HeaderCell>Document Type</HeaderCell>
        <Cell dataKey="agent" />
      </Column>

      <Column width={100} sortable>
        <HeaderCell>Type</HeaderCell>
        <Cell dataKey="type" />
      </Column>

      <Column width={150} sortable>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>
       <Column width={250}>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="updatedAt" />
      </Column>
    </Table>): (<Loader/>)}
          {births.success ? (<div className='table_pag' style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={getData().length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>): (<Loader size="md" backdrop content="loading notifications..." vertical ></Loader>)}
        
    </Container>
    
  );
};

export default NotificationTableBirths