import React, { useEffect, useState } from 'react'
import { Sidenav, Nav, Toggle, Button } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import { listNotificationSelectionA, listNotificationSelectionB, listNotificationSelectionD  } from '../actions/selectionActions';
import { listAuditSelectionA, listAuditSelectionB, listAuditSelectionD  } from '../actions/selectionActions';
import { NavDropdown, NavLink } from 'react-bootstrap';
import { AiOutlineAudit, AiOutlineUser } from 'react-icons/ai';
import { IoNotificationsCircleOutline, IoSettingsSharp } from 'react-icons/io5';
import { BiBarChartAlt2 } from 'react-icons/bi';

const styles = {
  width: 240,
  display: 'inline-table',
  marginRight: 10
};


function CustomSidenav({children}) {
  let curLocation
  let audits = false
  let general = false
  let events = false
  
  if(window.location.pathname === '/notifications/events'){
    events= true
  }
  else if(window.location.pathname === '/audits'){
    audits = true
  }
  else if(window.location.pathname === '/notifications/general/sms' || window.location.pathname === '/notifications/general/email'){
    general = true
  }
  

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true)
  const toggle = ()=> setIsOpen(!isOpen)
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const logoutHandler = () => {
    dispatch(logout());

  };
  const selectBirths = ()=> {
    dispatch(listNotificationSelectionB())
  }
   const selectDeaths = ()=> {
    dispatch(listNotificationSelectionD())
  }
   const selectAdoptionAudits = ()=> {
    dispatch(listAuditSelectionA())
  }
   const selectBirthAudits = ()=> {
    dispatch(listAuditSelectionB())
  }
   const selectDeathAudits = ()=> {
    dispatch(listAuditSelectionD())
  }
   const selectAdoptions = ()=> {
    dispatch(listNotificationSelectionA())
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      
    }
  }, [navigate, userInfo]);

const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState('1');
  return (
    <div  style={{display: 'flex'}}>
      <Sidenav defaultOpenKeys={['4']} style={{ width: 240 }}>
        <Sidenav.Body>
          <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item eventKey="1" icon={<BiBarChartAlt2/>} as={Link} to='/metrics'>
              Metrics
              </Nav.Item>
        
              <Nav.Item eventKey="2" icon={<AiOutlineAudit/>} as={Link} to='/audits' >
              Audits
            </Nav.Item>
            <Nav.Menu placement="rightStart" eventKey="3" title="Notifications" icon={<IoNotificationsCircleOutline/>}>
              <Nav.Item eventKey="3-1" as={Link} to='/notifications/events'>Events</Nav.Item>
              <Nav.Item eventKey="3-2" as={Link} to='/notifications/general/sms'>General</Nav.Item>
            </Nav.Menu>
            {userInfo.data.user.role === 'admin' ? (<>
             <Nav.Item eventKey="4" icon={<AiOutlineUser/>} as={Link} to='/users'>
              Users
            </Nav.Item>
             <Nav.Item eventKey="5" icon={<IoSettingsSharp/>} as={Link} to='/configuration'>
              Configuration
            </Nav.Item></>): (<span></span>)}
            
          </Nav>
        </Sidenav.Body>
        <Sidenav.Toggle expanded={expanded} onToggle={expanded => setExpanded(expanded)} />
      </Sidenav>
      <nav className='top_nav flex flex-f'>
          {userInfo ? (
            <div className='flex flex-f' style={{width: '100%'}}>
              <div className="sidebar_content branding flex flex-f">
                <img src="https://hrm.nira.go.ug/media/custom/images/nira-logo-login.png" alt="" style={{display: isOpen ? "block": "none"}}/>
                <h1 className="logo" style={{display: isOpen ? "block": "none"}}>NIRA</h1>
              </div>
              <div className='navControllers'>
                 {general ? (<div className='navControllerBtns'>

                  <Link to='/notifications/general/sms'>SMS</Link>
                  <Link to='/notifications/general/email'>Emails</Link>

                 </div>): 
                 events ? 
                 (<div className='navControllerBtns'>
                  <Button onClick={selectBirths}>Births</Button>
                  <Button onClick={selectDeaths}>Deaths</Button>
                  <Button onClick={selectAdoptions}>Adoptions</Button>

                 </div>) :
                  audits ? (<div className='navControllerBtns'>
                  <Button onClick={selectBirthAudits}>Births</Button>
                  <Button onClick={selectDeathAudits}>Deaths</Button>
                  <Button onClick={selectAdoptionAudits}>Adoptions</Button>

                 </div>) : (<div></div>)}

              </div>
              <NavDropdown title={userInfo.data.user.name} id="username">
                  <Link to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </Link>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>

            </div>
                
              ) : (
                <Nav.Link href="/login">
                  <i className="fas fa-user"></i>Sign In
                </Nav.Link>
              )}
        </nav>
      <main>{children}</main>
    </div>
  );
}

export default CustomSidenav
