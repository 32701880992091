import {SMS_NOTIFICATION_LIST_REQUEST, SMS_NOTIFICATION_LIST_SUCCESS, SMS_NOTIFICATION_LIST_FAIL, EMAIL_NOTIFICATION_LIST_REQUEST, EMAIL_NOTIFICATION_LIST_SUCCESS, EMAIL_NOTIFICATION_LIST_FAIL, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS, NOTIFICATION_LIST_FAIL, SMS_NOTIFICATION_DETAILS_REQUEST, SMS_NOTIFICATION_DETAILS_SUCCESS, SMS_NOTIFICATION_DETAILS_FAIL, EMAIL_NOTIFICATION_DETAILS_REQUEST, EMAIL_NOTIFICATION_DETAILS_SUCCESS, EMAIL_NOTIFICATION_DETAILS_FAIL} from '../constants/notificationConstants'
import axios from 'axios'

export const listNotifications = (token) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
     
    };
    try {
        dispatch({type: NOTIFICATION_LIST_REQUEST})

        const {data} = await axios.post('https://bdar.cml.ug/api/v1/notifications/birth-records/notifications', config, { category: 'all', status: 'all'})

        dispatch({type: NOTIFICATION_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: NOTIFICATION_LIST_FAIL, payload: error})
    }
}

export const listSmsNotifications = (token) => async (dispatch) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        dispatch({type: SMS_NOTIFICATION_LIST_REQUEST})
       
        const {data} = await axios.get('https://bdar.cml.ug/api/v1/notifications/sms', config)

        dispatch({type: SMS_NOTIFICATION_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: SMS_NOTIFICATION_LIST_FAIL, payload: error})
    }
}

export const smsNotificationDetails = (token, sms_id) => async (dispatch) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        dispatch({type: SMS_NOTIFICATION_DETAILS_REQUEST})
       
        const {data} = await axios.get(`https://bdar.cml.ug/api/v1/notifications/sms/${sms_id}`, config)

        dispatch({type: SMS_NOTIFICATION_DETAILS_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: SMS_NOTIFICATION_DETAILS_FAIL, payload: error})
    }
}

export const listEmailNotifications = (token) => async (dispatch) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        dispatch({type: EMAIL_NOTIFICATION_LIST_REQUEST})
       
        const {data} = await axios.get('https://bdar.cml.ug/api/v1/notifications/email', config)

        dispatch({type: EMAIL_NOTIFICATION_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: EMAIL_NOTIFICATION_LIST_FAIL, payload: error})
    }
}

export const emailNotificationDetails = (token, email_id) => async (dispatch) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        dispatch({type: EMAIL_NOTIFICATION_DETAILS_REQUEST})
       
        const {data} = await axios.get(`https://bdar.cml.ug/api/v1/notifications/email/${email_id}`, config)

        dispatch({type: EMAIL_NOTIFICATION_DETAILS_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: EMAIL_NOTIFICATION_DETAILS_FAIL, payload: error})
    }
}



