import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import Highcharts from 'highcharts'
import { Col, Row } from 'react-bootstrap';
import StatusIcon from '../components/icons/StatusIcon';




function EmailsPieChart() {


  document.addEventListener('DOMContentLoaded', function () {
        const chart = Highcharts.chart('EmailsPieChartsNew', {
            chart: {
                type: 'pie',
                polar: true,
                height: 190,
                backgroundColor: '#212529'
            },
            title: {
                text: 'Email',
                style: {
                  color: '#fff'
                },
                align: 'center',
                verticalAlign: 'middle'

            },
            xAxis: {
                categories: ['Apples', 'Bananas', 'Oranges'],             
            },
            yAxis: {
                title: {
                    text: 'Fruit eaten'
                }
            },
            credits:{
              enabled: false
            },
            series: [{
                name: 'Emals',
                data: [30, 20, 70, 3, 300],
                colors: ['#ea3b5a', '#fb9531', '#2e9fef', '#9749e7', '#0d8060'],
                innerSize: '60%'
            }],
            plotOptions:{
              pie:{
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                  enabled: false
                }
              }
            },
        });
    });



 const emailStatusData = [{
      id: 1,
      status: 'Sent',
      number: 505,
      icon: <StatusIcon bgcolor={'#0d8060'}/>

    },
  {
    id: 2,
    status: 'Not sent',
    number: 72,
    icon: <StatusIcon bgcolor={'#2e9fef'}/>
  },
{   
    id: 3,
    status: 'Unavailable',
    number: 300,
    icon: <StatusIcon bgcolor={'#fb9531'}/>
  },
{
    id: 4,
    status: 'Failed',
    number: 29,
    icon: <StatusIcon bgcolor={'#ea3b5a'}/>
  },
{
    id: 5,
    status: 'Pending',
    number: 30,
    icon: <StatusIcon bgcolor={'#9749e7'}/>
  }]
   
  
  return (
    <Row className='flex'>

      <Col id="EmailsPieChartsNew" style={{width: "100%", height: "210px"}}></Col>
      <Col id="labels" tyle={{width: "100%", height: "100%"}}>
        {emailStatusData.map((email)=> (
          <Row key={email.id}>
            <Col style={{fontSize: "11px"}}>
              <span style={{display: "flex", alignItems: "center"}}>{email.icon} {email.status}</span>
              
            </Col>
            <Col style={{fontSize: "11x"}}>{email.number}</Col>

          </Row>
        ))}
      </Col>

    </Row>
  )
}

export default EmailsPieChart
