import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {birthReducer} from './reducers/birthReducers'
import { auditReducer } from './reducers/auditReducers'
import { emailNotificationReducer, notificationReducer } from './reducers/notificationReducers'
import { metricsReducer } from './reducers/metricsReducers'
import { userLoginReducer, userListReducer } from './reducers/userReducers'
import { smsNotificationReducer } from './reducers/notificationReducers'
import { notificationSelections } from './reducers/selectionReducers'
import { auditSelections } from './reducers/selectionReducers'
import { smsDetailsReducer, emailDetailsReducer } from './reducers/notificationReducers'

const reducer = combineReducers({
    birthList: birthReducer,
    auditList: auditReducer,
    notificationList: notificationReducer,
    metricsList: metricsReducer,
    userLogin: userLoginReducer,
    userList: userListReducer,
    smsNotificationList: smsNotificationReducer,
    emailNotificationList: emailNotificationReducer,
    notificationSelection : notificationSelections,
    auditSelection: auditSelections,
    smsNotification: smsDetailsReducer,
    emailNotification: emailDetailsReducer


})
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};
const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store