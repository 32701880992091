import { NOTIFICATION_LIST_FAIL, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS, SMS_NOTIFICATION_LIST_REQUEST, SMS_NOTIFICATION_LIST_SUCCESS, SMS_NOTIFICATION_LIST_FAIL, EMAIL_NOTIFICATION_LIST_REQUEST, EMAIL_NOTIFICATION_LIST_SUCCESS, EMAIL_NOTIFICATION_LIST_FAIL, SMS_NOTIFICATION_DETAILS_REQUEST, SMS_NOTIFICATION_DETAILS_SUCCESS, SMS_NOTIFICATION_DETAILS_FAIL, EMAIL_NOTIFICATION_DETAILS_REQUEST, EMAIL_NOTIFICATION_DETAILS_SUCCESS, EMAIL_NOTIFICATION_DETAILS_FAIL} from '../constants/notificationConstants'

export const notificationReducer = (state = { notifications: []}, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST_REQUEST:
            return {loading : true, notifications: []}
            
        case NOTIFICATION_LIST_SUCCESS:
            return {loading: false, notifications: action.payload
            }
        case NOTIFICATION_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

export const smsNotificationReducer = (state = { smsNotifications: []}, action) => {
    switch (action.type) {
        case SMS_NOTIFICATION_LIST_REQUEST:
            return {loading : true, smsNotifications: []}
            
        case SMS_NOTIFICATION_LIST_SUCCESS:
            return {loading: false, smsNotifications: action.payload
            }
        case SMS_NOTIFICATION_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

export const smsDetailsReducer = (state = { smsDetails: {}}, action) => {
    switch (action.type) {
        case SMS_NOTIFICATION_DETAILS_REQUEST:
            return {loading : true, smsDetails: {}}
            
        case SMS_NOTIFICATION_DETAILS_SUCCESS:
            return {loading: false, smsDetails: action.payload
            }
        case SMS_NOTIFICATION_DETAILS_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

export const emailNotificationReducer = (state = { emailNotifications: []}, action) => {
    switch (action.type) {
        case EMAIL_NOTIFICATION_LIST_REQUEST:
            return {loading : true, emailNotifications: []}
            
        case EMAIL_NOTIFICATION_LIST_SUCCESS:
            return {loading: false, emailNotifications: action.payload
            }
        case EMAIL_NOTIFICATION_LIST_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}

export const emailDetailsReducer = (state = { emailDetails: {}}, action) => {
    switch (action.type) {
        case EMAIL_NOTIFICATION_DETAILS_REQUEST:
            return {loading : true, emailDetails: {}}
            
        case EMAIL_NOTIFICATION_DETAILS_SUCCESS:
            return {loading: false, emailDetails: action.payload
            }
        case EMAIL_NOTIFICATION_DETAILS_FAIL:
            return {loading: false, error: action.payload
            }
        default:
            return state
    }

}


