import React, { useEffect} from 'react'

import { Table } from 'react-bootstrap';
import NotificationTable from '../components/NotificationsTableDeaths';
import SearchIcon from '@rsuite/icons/Search';
import SearchBox from '../components/SearchBox';

import { Col, Container, Input, InputGroup, Pagination, Row } from 'rsuite';
import { IoSearchCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import NotificationTableBirths from '../components/NotificationsTableBirths';
import NotificationTableDeaths from '../components/NotificationsTableDeaths';
import NotificationTableBirthRecords from '../components/NotificationsTableBirthRecords';
import NotificationTableAdoptions from '../components/NotificationsTableAdoptions';

const HomeScreen = () => {
const notificationSelection = useSelector((state)=> state.notificationSelection)
const selected = notificationSelection.notificationSelection

  return (
   
      <Container className=" c_wrapper">
        
          <div className="c_wrapper_header">
            <h2>Event Notifications</h2>
            
          </div>
          <div className="c_wrapper_content">
            <Row className="c_wrapper_content_header flex flex-f">
              <Col className='flex flex-c'>
                <h5>{(selected === 'births') ? 'Birth' : (selected === 'deaths') ? 'Death' : 'Adoption'} Notifications</h5>
              </Col>
              <SearchBox></SearchBox>
            </Row>
            {(selected === 'births') ? (<NotificationTableBirthRecords></NotificationTableBirthRecords>) : (selected === 'adoptions') ? (<NotificationTableAdoptions></NotificationTableAdoptions>) :  (selected === 'deaths')? (<NotificationTableDeaths></NotificationTableDeaths>) : (<NotificationTableAdoptions></NotificationTableAdoptions>)}
            <div className="c_wrapper_content_footer">
               
            </div>
          </div>
       
      </Container>
    
  )
}

export default HomeScreen
