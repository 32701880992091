import axios from 'axios'
import { ErrorBoundary } from '../Utils/ErrorBoundary'

import React from 'react'


const TestScreen = () => {
  return (
    <ErrorBoundary>
      <div className='c_wrapper'>
        <div className="c_wrapper_header">
          <h2>Configurations</h2>
          
        </div>
      
      </div>
    </ErrorBoundary>
    
  )
}

export default TestScreen
