import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
   labels: ['Sent', 'Pending', 'Unavailable', 'Failed'],
  maintainAspectRatio: false,
  datasets: [
    {
      label: '# of Votes',
      data: [12, 5, 3, 50],
      backgroundColor: [
        'rgba(85,216,254,255)',
        'rgba(255,200,5,255)',
        'rgba(218,115,255,255)',
        'rgba(186,31,25,255)',
        
      ],
      borderColor: [
        'rgba(85,216,254,255)',
        'rgba(255,200,5,255)',
        'rgba(218,115,255,255)',
        'rgba(186,31,25,255)',
        
      ],
      borderWidth: 0,
    },
  ],
};

export const chartOptions = {
  plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          textAlign: 'left',
          useBorderRadius: true,
          borderRadius: 0,
          
        }
      },
     title: {
        display: false,
        fontSize: 25,
        text: 'Email Notifications',
      },
    },
    cutout: '65%',
    percentageInnerCutout: 40
    
}

const EmailsChart = () => {
  return (
    <div style={{width: "280px", height: "280px"}}>
       <Doughnut data={data} options={chartOptions} />
    </div>
  )
}

export default EmailsChart
