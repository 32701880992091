import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts'
import { DateRangePicker, Loader } from 'rsuite';
import moment from "moment"
import MetricsNums from '../components/metrics/MetricsNums';
import {ProgressBar} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import SmsPieChart from '../charts/SmsCharts';
import EmailsPieChart from '../charts/EmailCharts';
import { listBirths } from '../actions/birthActions';
import { listAudits } from '../actions/auditActions';
import { listNotifications } from '../actions/notificationActions ';
import { Spinner } from 'react-bootstrap'
import { listMetrics } from '../actions/metricsActions';
import StatusIcon from '../components/icons/StatusIcon';
import ApplicationsChart from '../components/metrics/ApplicationsChart.tsx';
import SmsChart from '../components/metrics/SmsChart.tsx';
import EmailsChart from '../components/metrics/EmailsChart.tsx';
import LineChartBirths from '../components/metrics/LineChartBirths.tsx';
import ChartAdoptionsNums from '../components/metrics/ChartAdoptionsNums.tsx';
import ChartDeathsNums from '../components/metrics/ChartDeathsNums.tsx';
import ChartBirthsNums from '../components/metrics/ChartBirthsNums.tsx';
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from '../Utils/ErrorBoundary';



function Notifications() {
  const navigate = useNavigate()
    const [startDate, setStartDate] = useState(new Date("2014/02/08"));
    const [endDate, setEndDate] = useState(new Date("2014/04/08"));

    const dispatch = useDispatch()
    const birthList = useSelector((state) => state.birthList)
    const {loading, error, births} = birthList
    const auditList = useSelector((state) => state.auditList)
    const {audits} = auditList

    const notificationList = useSelector((state) => state.notificationList)
    const {notifications} = notificationList

    const metricsList = useSelector((state) => state.metricsList)
    const {metrics} = metricsList
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
    useEffect(() => {
     if(userInfo){
      dispatch(listBirths())
      dispatch(listAudits())
      dispatch(listNotifications())
      dispatch(listMetrics())
     }else{
      navigate("/")
     }
      
      

  
    }, [userInfo, dispatch, navigate])


  return (
    <ErrorBoundary>
      <Container className='c_wrapper'>
      <Row lg={1} className="all_stats" >
        <div className="c_wrapper_header c_wrapper_header_metrics flex flex-f ">
           <h2>Overview</h2>
           <div className="date-selector">
            <DateRangePicker placement='bottomEnd' size='sm' showWeekNumbers format='dd-MM-yyyy' block={true} preventOverflow={true} character=' - ' limitEndYear={20} onOk={(values) =>{
              const startDate  = moment(values[0]).format('DD-MM-YYYY')
              const endDate  = moment(values[1]).format('DD-MM-YYYY')

              console.log(startDate, endDate)
            }}/>
           </div>

        </div>

        <Col className="stats-section stats-section_counts"> 
          <Row lg={1} className="main_stats__nums_comp c-shadow"> 
            
            {metrics.success ? (<Row className='numsContainer'>
            <div className='numsCount' style={{color: "#000"}}>
                <p>Births</p>
                <h2>{metrics.stats.births.applications}</h2>
                <p className='changeStats'><span className='changeStats_de changeStats_percentage'>1.3% &#8595;</span> from last year</p>
            </div>
            <div className='numsChart'>
              <ChartBirthsNums></ChartBirthsNums>
            </div>
            </Row>) : (<Loader animation="border" role="status"></Loader>)}
            
          </Row>
          <Row lg={1} className="main_stats__nums_comp c-shadow"> 
            
            {metrics.success ? (<Col className='numsContainer'>
            <div className='numsCount' style={{color: "#000"}}>
                <p>Deaths</p>
                <h2>{metrics.stats.deaths.applications}</h2>
                <p className='changeStats'><span className='changeStats_de changeStats_percentage'>1.3% &#8595;</span> from last year</p>
                
            </div>
            <div className='numsChart'>
              <ChartDeathsNums></ChartDeathsNums>
            </div>
            </Col>) : (<Spinner animation="border" role="status"></Spinner>)}
            
          </Row>
          <Row lg={1} className="main_stats__nums_comp c-shadow"> 
            
            {metrics.success ? (<Col className='numsContainer'>
            <div className='numsCount' style={{color: "#000"}}>
                <p>Adoptions</p>
                <h2>50350</h2>
                <p className='changeStats'><span className='changeStats_in changeStats_percentage'>2.3% &#8593; </span> from last year</p>
            </div>
            <div className='numsChart'>
              <ChartAdoptionsNums></ChartAdoptionsNums>
            </div>
            </Col>) : (<Spinner animation="border" role="status"></Spinner>)}
            
          </Row>
            
        </Col>
       
        <Col className='stats-section'>
          
                <ApplicationsChart></ApplicationsChart>

        </Col>
        <Row className="stats-section stats-section_pies flex">
          <Col className='chartContainer chartContainer_pie'>
            <h3>SMS Notification</h3>

          <EmailsChart></EmailsChart>
          </Col>
          <Col className='chartContainer chartContainer_pie'>
            <h3>Email Notification</h3>

          <SmsChart></SmsChart>
          </Col>
          <Col className='chartContainer chartContainer_pie chartContainer_pie__cpu'>
            <h3>CPU USAGE</h3>
            <LineChartBirths></LineChartBirths>
          </Col>
            
        </Row>
        
      </Row>
    </Container>
    </ErrorBoundary>
    
    
  )
}

export default Notifications
