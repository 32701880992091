import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from '@faker-js/faker'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
   elements: {
    point:{
        radius: 0
    },
    line:{
      tension: 0.4
    }

      },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Total Applications',
    },
    legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        }
      }, 
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: false,
      position: 'left' as const,
      fontSize: 10,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
        lineWidth: 1
      },
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const data = {
  labels,
  datasets: [
     {
      label: 'Deaths',
      data: [2276, 2180, 1956, 1578, 2276,2018, 2435,2792,2370, 2172, 1778, 2983],
      borderColor: 'rgba(45,216,160,255)',
      backgroundColor: 'rgba(45,216,160,1)',
      yAxisID: 'y1',
      borderWidth: 2
    },
    {
      label: 'Deaths',
      data: [1076, 1180, 1056, 1178, 1276,918, 1435,1792,1370, 2072, 1278, 1483],
      borderColor: 'rgba(195,60,55,255)',
      backgroundColor: 'rgba(195,60,55,1)',
      yAxisID: 'y1',
      borderWidth: 2
    },
    {
      label: 'Adoptions',
      data: [676, 780, 1056, 278, 476,318, 435,292,470, 272, 278, 383],
      borderColor: 'rgba(254,211,56,255)',
      backgroundColor: 'rgba(254,211,56,1)',
      yAxisID: 'y1',
      borderWidth: 2
    },
  ],
};

const ApplicationsChart = () => {
  return (
    <div style={{height: "300px", width: "100%", background: "#fff"}}>
        <Line options={options} data={data} style={{height: "100%"}}/>
    </div>
  )
}

export default ApplicationsChart