import React, { useEffect, useState} from 'react'
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import {AiOutlineDashboard, AiOutlineAudit, AiOutlineUser} from 'react-icons/ai'
import {IoNotificationsCircleOutline} from 'react-icons/io5'
import {FaBars} from 'react-icons/fa'
import { Link, Navigate, NavLink, useNavigate } from 'react-router-dom';
import {IoSettingsSharp} from 'react-icons/io5'
import {BiBarChartAlt2} from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import { Nav, NavDropdown } from 'react-bootstrap';

 

function SideNav({children}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true)
  const toggle = ()=> setIsOpen(!isOpen)
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const logoutHandler = () => {
    dispatch(logout());

  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
      
    }
  }, [navigate, userInfo]);


  const menuItem = [
    {
    path: '/metrics',
    name: 'Metrics',
    icon: <BiBarChartAlt2/>
  },
   {
    path: '/notifications',
    name: 'Notifications',
    icon: <IoNotificationsCircleOutline/>
  },
  {
    path: '/audits',
    name: 'Audits',
    icon: <AiOutlineAudit/>
  },
  {
    path: '/users',
    name: 'Users',
    icon: <AiOutlineUser/>
  },
  {
    path: '/configuration',
    name: 'Configuration',
    icon: <IoSettingsSharp/>
  },
 
]
  return (
    
    <div className='container'>
      
        <div className="sidebar" style={{width: isOpen ? "250px" : "80px"}}>
          <div className="sidebar_content flex">
            <div className="branding flex">
              <img src="https://hrm.nira.go.ug/media/custom/images/nira-logo-login.png" alt="" style={{display: isOpen ? "block": "none"}}/>
              <h1 className="logo" style={{display: isOpen ? "block": "none"}}>NIRA</h1>
            </div>
            <div className="bars flex">
              <FaBars onClick={toggle}/>
            </div>
          </div>
          {
            menuItem.map((item, index)=>
              (<NavLink to={item.path} key={index} className="link">

                <div className="icon">{item.icon}</div>
                <div className="link_text" style={{display: isOpen ? "block" : "none"}}>{item.name}</div>
              </NavLink>)
            )
          }
          
        </div>
        <nav className='top_nav'>
          {userInfo ? (
                <NavDropdown title={userInfo.data.user.name} id="username">
                  <Link to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </Link>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link href="/login">
                  <i className="fas fa-user"></i>Sign In
                </Nav.Link>
              )}
        </nav>
        <main>{children}</main>
      
    </div>
  )
}

export default SideNav
